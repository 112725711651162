/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.shado {
  box-shadow: 0 0 5px 0.5px rgb(126, 125, 125);
}
body,
html {
  overflow-x: hidden;
  background-color: #e6e6e6;
}
.carousel .slider-wrapper {
  height: auto !important;
}

.carousel-image {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .carousel-image {
    height: 800px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .carousel-image {
    height: 400px; /* Adjust the height for mobile view */
    width: 100%;
  }
}
